<template>
  <section>
    <div class="text-center mt-2 mb-2" v-if="isloadingProducts">
      <b-spinner label="Loading..." variant="info" />
    </div>

    <b-card v-if="productDetail" no-body style="max-width: 100%; " >
      <b-row>
        <div class="col-xs-4 col-md-4">
          <b-card-img style="height:600px;" :src="imgUrl + productDetail.coverImage" :alt="`Photo ${productDetail.name}`" />
        </div>
        <div class="col-xs-5 col-md-5">
          <h4 class="mt-2">{{ productDetail.name }}</h4>
          <b-badge variant="primary" class="mb-2">Category:  {{ productDetail.categoryName }}</b-badge>
          <b-card-text class="text-justify scrolling-wrapper-description border p-1" v-if="isHtml">
            <div v-html="productDetail.description" class="scrollbar-vertical"></div>
          </b-card-text>
          <b-card-text class="text-justify scrolling-wrapper-description border p-1" v-else>
            <div class="scrollbar-vertical">{{(productDetail.description)}}</div>
          </b-card-text>
        </div>

        <div class="col-xs-3 col-md-3">
          <div class="p-1 float-right">
            <b-button variant="warning" size="sm"  @click="$router.back()">
              <feather-icon icon="ArrowLeftIcon" size="16" /> Volver
            </b-button>
          </div>

          <br><br><br>

          <div class="border p-1 mr-1">
				<b-alert variant="primary" show>
					<div class="alert-body ">
						<span><strong>Indica una cantidad</strong> para añadir al carrito.</span>
					</div>
				</b-alert>

				<ExperiencesDetails  :experiencesDetails="productDetail.detail" :product="productDetail" />
				<BtnViewInCart :product="productDetail"/>

				
			</div>

			<div class="p-1 mt-2" v-if="productDetail && payloadLinks">
				<BtnsDetailsPdfLinks :product="productDetail" :payloadRequest="payloadLinks" :queryParams="payloadPeticion" />
			</div>

        </div>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import ExperiencesDetails from '@/modules/shop/components/experiences/ExperiencesDetails'
import BtnViewInCart from '@/modules/shop/components/experiences/BtnViewInCart'
import BtnsDetailsPdfLinks from '@/modules/shop/components/experiences/BtnsDetailsPdfLinks'

import { market } from '@/modules/shop/mixins/market'
import { toJson } from '@/helpers/helpers'

export default {
	mixins: [ market ],
	directives: {
		Ripple,
	},
	components: {
		ExperiencesDetails,
		BtnViewInCart,
		BtnsDetailsPdfLinks
	},
	props:{
		slug:{
			type: String,
			required: true
		}
	},
	created() {
		this.unsetDetailProduct()
		this.slugFromUrl = this.$route.params.slug
		this.makePayloadFromRouteParams( this.slugFromUrl )
	},
	async mounted(){
		if( this.payloadPeticion ){
			this.fetchExperienceDetail( this.payloadPeticion )
		}
	},
	data() {
		return {
			slugFromUrl: '',
			payloadPeticion: null,
			imgUrl: process.env.VUE_APP_IMGEXP_SRC_API,
			payloadLinks: null
		}
	},
	computed:{
		...mapState('start',['payloadParamsRates']),
		...mapState('shop',['cart', 'currency']),
		...mapState('products',['isloadingProducts','productDetail']),
		...mapGetters('products',['filteredProducts']),
		slugUrl() {
			return this.slugFromUrl
		},
		isHtml(){
			return /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test( this.productDetail.description )
		}
	},
	methods: {
		...mapActions('products',['fetchProducts','fetchExperienceDetail','calculateTotalWithDiscounts']),
		...mapMutations('products',['unsetDetailProduct','resetQtyProductDetail']),
		...mapMutations('shop',['addProductInCart','addProductsFromPackage']),

		makePayloadFromRouteParams( cadena ){
			const arreglo = cadena.split("_")
			const id = arreglo[0]
			const dateRange= arreglo[1]
			const adults= arreglo[2]
			const children= arreglo[3]
			const idCat= arreglo[4]
			this.payloadPeticion = { id, dateRange, adults,children }
			this.payloadLinks = { type: 'experiences', idItem: id }


			return this.payloadPeticion
		},
		async handlerAddToCart(product){
			let item = toJson({...product}) //spread
			const productsAddCart = this.generateProductsToAddCart(item) //desde el mixin
			//ciclo asyncrono para ir añadiendo al cart
			productsAddCart.forEach( async productCart => {
				const productFormatted = this.formatProductBeforeAddingToCart(productCart) //desde el mixin, formateo el item
				const productIsRepeated = this.existsInCart( productFormatted )
				if( !productIsRepeated ){ // si producto no se repite
					await this.manageAddProductInCart(productFormatted, productFormatted.categoryName) // agrego el producto formateado
				} else {
					await this.addOneMoreQty(productFormatted)
				}
			})
			this.resetQtyProductDetail( { idProduct:product.id } ) // reseteo los qty detail del producto
		},
		async addOneMoreQty(product){
			const { modelType, detailId, qty }  = product
			const prodInCart = this.cart.items.find( product => product.detailId === detailId && product.modelType === modelType )
			prodInCart.qty = prodInCart.qty + qty//aumento la cantidad
			await this.calculateTotalWithDiscounts()
			await this.qtyHasChanged(prodInCart) // desde el mixim market
		},
	},
	watch:{
		async slug(){
			this.unsetDetailProduct()
			this.slugFromUrl = this.$route.params.slug
			this.makePayloadFromRouteParams( this.slugFromUrl )
			if( this.payloadPeticion ){
				await this.fetchExperienceDetail( this.payloadParamsRates )
			}
		},
		currency:{
			deep: true,
			async handler() {
				this.unsetDetailProduct()
				this.slugFromUrl = this.$route.params.slug
				const payload = this.makePayloadFromRouteParams( this.slugFromUrl )
				if( payload ){
					await this.fetchExperienceDetail( payload )
				}
			}
		}
	}
}
</script>

<style lang="scss">

.scrollbar-vertical {
  height: 450px;
  overflow: auto;
  padding-inline-end: .5rem;
}

.scrollbar-vertical::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.scrollbar-vertical::-webkit-scrollbar{
  width: 6px;
  background-color: #F5F5F5;
}

.scrollbar-vertical::-webkit-scrollbar-thumb {
  background-color: #333366 ;
  background-image: -webkit-linear-gradient(45deg,rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
}

.scrolling-wrapper-description{
  overflow-x: auto;
}

.scrolling-wrapper-description::-webkit-scrollbar {
  height: 6px;
  background-color: #F5F5F5;
}

.scrolling-wrapper-description::-webkit-scrollbar-thumb{
  background-color: #ff9f43;
  background-image: -webkit-linear-gradient(45deg,rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
}
.detail-item{
  font-size: .8rem;
}
.badge-price{
  font-size: .65rem;
}
.text-total-product{
  text-decoration: underline solid #FFD700 3px;
}
</style>